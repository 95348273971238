import { CognitoUser } from '@aws-amplify/auth';
import { accountService } from '../services';

export const getEncryptedToken = async (user?: CognitoUser) => {
	if (!user) {
		throw new Error('user is undefined.');
	}

	const session = user.getSignInUserSession();

	if (!session) {
		throw new Error('user session is undefined.');
	}

	const jwtToken = session.getIdToken().getJwtToken();

	try {
		const encryptedToken = await accountService.verifySignIn(jwtToken).fetch();
		return encryptedToken;
	} catch (error) {
		throw error;
	}
};
