import { httpSingleton } from '@/lib/http-client';

export const accountService = {
	verifySignIn(token: string) {
		return httpSingleton.orchestrateRequest<string>({
			method: 'POST',
			url: `/auth-service/api/v1/account/sign-in`,
			headers: { Authorization: 'Bearer ' + token },
		});
	},
};
