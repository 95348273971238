import React from 'react';
import { Redirect } from 'react-router-dom';
import {
	AcceptInvite,
	ChangeMfaMethod,
	ChangePassword,
	ForgotPassword,
	ForgotPasswordSubmit,
	NoMatch,
	SignIn,
	Version,
} from '@/pages';

import { HeaderNoAuth } from '@/components';

export const routes = [
	{
		path: '/',
		exact: true,
		private: false,
		header: () => null,
		main: () => <Redirect to="/sign-in" />,
	},
	{
		path: '/sign-in',
		exact: true,
		private: false,
		header: () => null,
		main: SignIn,
	},
	{
		path: '/forgot-password',
		exact: true,
		private: false,
		header: HeaderNoAuth,
		main: ForgotPassword,
	},
	{
		path: '/forgot-password-submit',
		exact: true,
		private: false,
		header: HeaderNoAuth,
		main: ForgotPasswordSubmit,
	},
	{
		path: '/change-mfa-method',
		exact: true,
		private: true,
		header: HeaderNoAuth,
		main: ChangeMfaMethod,
	},
	{
		path: '/accept-invite/:inviteId',
		exact: true,
		private: false,
		header: HeaderNoAuth,
		main: AcceptInvite,
	},
	{
		path: '/change-password',
		exact: true,
		private: true,
		header: HeaderNoAuth,
		main: ChangePassword,
	},
	{
		path: '/version',
		exact: true,
		private: false,
		header: HeaderNoAuth,
		main: Version,
	},
	{
		path: '*',
		exact: false,
		private: false,
		header: HeaderNoAuth,
		main: NoMatch,
	},
];
