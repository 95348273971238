import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider as BootstrapThemeProvider } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { getEncryptedToken, refreshCognitoUserSession } from '@/lib/utils';
import config from '@/lib/config';
import { COOKIES } from '@/lib/models';
import { publicService } from '@/lib/services';
import { usePlatformsContext, useQuery, useRedirectUser } from '@/hooks';
import {
	AlertProvider,
	AutoSignInProvider,
	ThemeProvider as MasterClientThemeProvider,
	PlatformsProvider,
} from '@/contexts';
import { Alert, Footer, Loader, PrivateRoute } from '@/components';
import { prefixes } from '@/jss/bootstrap';
import { useGlobalStyles } from '@/jss/hooks/use-global-styles';
import { useThemedBootstrapStyles } from '@/jss/hooks/use-themed-bootstrap-styles';
import 'react-phone-number-input/style.css';

import { routes } from '@/routes';

Amplify.configure({
	region: config.HACKETT_USER_AUTH_AMPLIFY_REGION,
	userPoolId: config.HACKETT_USER_AUTH_AMPLIFY_USER_POOL_ID,
	userPoolWebClientId: config.HACKETT_USER_AUTH_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
});

// Wrapped by <Router>, allows use of 'react-router-dom' hooks
const RoutedApp = () => {
	const { pathname } = useLocation();
	const { setPlatforms } = usePlatformsContext();
	const redirectUser = useRedirectUser();

	const query = useQuery();
	const redirectUrl = decodeURIComponent(query.get('redirectUrl') || '');
	const platform = useMemo(() => query.get('platform') ?? '', [query]);

	const [isLoading, setIsLoading] = useState(true);
	const appIsLoaded = useRef(false);

	// When the application mounts, save the URL that user came from into a cookie.
	// That way we can navigate them back to their destination after they authenticate.
	useEffect(() => {
		if (redirectUrl) {
			Cookies.set(COOKIES.REDIRECT_URL, redirectUrl);
		}
	}, [redirectUrl]);

	// Scroll to top of page on pathname change
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const checkAuthentication = useCallback(async () => {
		try {
			const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
			await refreshCognitoUserSession(currentUser);
			const encryptedToken = await getEncryptedToken(currentUser);

			redirectUser(encryptedToken);
		} catch (error) {
			throw error;
		}
	}, [redirectUser]);

	const fetchPlatform = useCallback(async () => {
		if (!platform) {
			return;
		}

		try {
			const response = await publicService.getPlatforms({ platform: [platform] }).fetch();
			setPlatforms(response.platforms);
		} catch (error) {
			throw error;
		}
	}, [platform, setPlatforms]);

	const initApp = useCallback(async () => {
		if (appIsLoaded.current) {
			return;
		}

		const changeAuthRoutes = ['/change-password', '/change-mfa-method'];
		const isChangeAuthRoute = changeAuthRoutes.some((route) => window.location.pathname.includes(route));

		try {
			setIsLoading(true);

			if (isChangeAuthRoute) {
				// Auto reject to force platform fetch and load app
				// without checking cognito user, refresh, or redirect
				await Promise.reject();
			} else {
				await checkAuthentication();
			}
		} catch (_authError) {
			try {
				await fetchPlatform();
			} catch (_platformError) {
				// Do not handle, let fail silently
			} finally {
				appIsLoaded.current = true;
				setIsLoading(false);
			}
		}
	}, [checkAuthentication, fetchPlatform]);

	useEffect(() => {
		initApp();
	}, [initApp]);

	return (
		<>
			{isLoading ? (
				<div className="pt-20 d-flex justify-content-center align-items-center">
					<Loader />
				</div>
			) : (
				<>
					<Alert />
					<Switch>
						{routes.map((route, index) => {
							return (
								<Route key={index} path={route.path} exact={route.exact} children={<route.header />} />
							);
						})}
					</Switch>
					<Switch>
						{routes.map((route, index) => {
							return React.createElement(route.private ? PrivateRoute : Route, {
								key: index,
								path: route.path,
								exact: route.exact,
								children: <route.main />,
							});
						})}
					</Switch>
					<Footer />
				</>
			)}
		</>
	);
};

// Wrapped by various <ThemeProviders>, allows use of 'jss' hooks
const ThemedApp = () => {
	useGlobalStyles();
	useThemedBootstrapStyles();

	return (
		<Router>
			<RoutedApp />
		</Router>
	);
};

// Exported App, not wrapped by anything, can add various context providers here
const App = () => {
	return (
		<MasterClientThemeProvider>
			<BootstrapThemeProvider prefixes={prefixes}>
				<AutoSignInProvider>
					<AlertProvider>
						<PlatformsProvider>
							<ThemedApp />
						</PlatformsProvider>
					</AlertProvider>
				</AutoSignInProvider>
			</BootstrapThemeProvider>
		</MasterClientThemeProvider>
	);
};

Sentry.init({
	dsn: config.SENTRY_DSN,
	release: __VERSION__,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(createBrowserHistory()),
		}),
		// DO NOT USE, causes performance slow down
		//new Sentry.Replay(),
	],

	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default App;
