import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export function refreshCognitoUserSession(cognitoUser: CognitoUser) {
	return new Promise((resolve: (session: CognitoUserSession) => void, reject: (error: Error) => void) => {
		cognitoUser.getSession(async (sessionError: Error | null, session: CognitoUserSession | null) => {
			if (sessionError) {
				reject(sessionError);
				return;
			}

			if (!session) {
				reject(new Error('Error getting CognitoUser session.'));
				return;
			}

			cognitoUser.refreshSession(
				session.getRefreshToken(),
				async (refreshError: Error | null, session: CognitoUserSession | null) => {
					if (refreshError) {
						reject(refreshError);
						return;
					}

					if (session) {
						resolve(session);
						return;
					}

					reject(new Error('Error refreshing CognitoUser token.'));
					return;
				}
			);
		});
	});
}
