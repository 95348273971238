import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { createUseThemedStyles } from '@/hooks';

import { ReactComponent as ChevronLeft } from '@/assets/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from '@/assets/icons/icon-chevron-right.svg';

const animationDurationInMs = 300;

const useStyles = createUseThemedStyles((theme) => ({
	carousel: {
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		position: 'relative',
	},
	slide: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		padding: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.colors.gray300,
	},
	slideContent: {
		width: '85%',
		'& img': {
			maxWidth: '100%',
		},
	},
	carouselControls: {
		bottom: 25,
		left: '50%',
		display: 'flex',
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		transform: 'translateX(-50%)',
		'& ul': {
			padding: 0,
			display: 'flex',
			margin: '0 10px',
			listStyle: 'none',
			'& li': {
				width: 10,
				height: 10,
				margin: '0 5px',
				borderRadius: 5,
				position: 'relative',
				backgroundColor: 'rgba(255,255,255,0.5)',
				'&:first-child': {
					marginLeft: 0,
				},
				'&:last-child': {
					marginRight: 0,
				},
				'&.active': {
					backgroundColor: theme.colors.white,
				},
			},
		},
	},
	arrowButton: {
		width: 44,
		height: 44,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:disabled': {
			backgroundColor: 'transparent !important',
		},
	},
	'@global': {
		'.carousel-previous-enter': {
			transform: 'translateX(-100%)',
		},
		'.carousel-previous-enter-active': {
			transition: `transform ${animationDurationInMs}ms`,
			transform: 'translateX(0%)',
		},
		'.carousel-previous-enter-done': {
			transform: 'translateX(0%)',
		},
		'.carousel-previous-exit': {
			transform: 'translateX(0%)',
		},
		'.carousel-previous-exit-active': {
			transition: `transform ${animationDurationInMs}ms`,
			transform: 'translateX(100%)',
		},
		'.carousel-previous-exit-done': {
			transform: 'translateX(100%)',
		},
		'.carousel-next-enter': {
			transform: 'translateX(100%)',
		},
		'.carousel-next-enter-active': {
			transition: `transform ${animationDurationInMs}ms`,
			transform: 'translateX(0%)',
		},
		'.carousel-next-enter-done': {
			transform: 'translateX(0%)',
		},
		'.carousel-next-exit': {
			transform: 'translateX(0%)',
		},
		'.carousel-next-exit-active': {
			transition: `transform ${animationDurationInMs}ms`,
			transform: 'translateX(-100%)',
		},
		'.carousel-next-exit-done': {
			transform: 'translateX(-100%)',
		},
	},
}));

interface CarouselSlide {
	imageUrl: string;
	htmlContent?: string;
	action: ButtonProps;
	slideContentStyle?: React.CSSProperties;
}

interface ExpandingCarouselProps {
	slides: CarouselSlide[];
}

export const ExpandingCarousel = ({ slides }: ExpandingCarouselProps) => {
	const classes = useStyles();

	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const [animationClassNames, setAnimationClassNames] = useState('carousel-next');
	const currentSlide = useMemo(() => slides[currentSlideIndex], [currentSlideIndex, slides]);

	const handlePreviousButtonClick = useCallback(() => {
		setCurrentSlideIndex((previousValue) => {
			if (previousValue === 0) {
				return slides.length - 1;
			}

			return previousValue - 1;
		});

		setAnimationClassNames('carousel-previous');
	}, [slides]);

	const handleNextButtonClick = useCallback(() => {
		setCurrentSlideIndex((previousValue) => {
			if (previousValue === slides.length - 1) {
				return 0;
			}

			return previousValue + 1;
		});

		setAnimationClassNames('carousel-next');
	}, [slides]);

	const handlePageButtonClick = useCallback((index: number) => {
		let previousIndex = 0;

		setCurrentSlideIndex((previousValue) => {
			previousIndex = previousValue;
			return index;
		});

		if (previousIndex > index) {
			setAnimationClassNames('carousel-previous');
		} else {
			setAnimationClassNames('carousel-next');
		}
	}, []);

	return (
		<div className={classes.carousel}>
			<TransitionGroup childFactory={(child) => React.cloneElement(child, { classNames: animationClassNames })}>
				<CSSTransition
					key={currentSlideIndex}
					classNames={animationClassNames}
					timeout={animationDurationInMs}
					mountOnEnter
					unmountOnExit
				>
					<div className={classes.slide} style={{ backgroundImage: `url(${currentSlide.imageUrl})` }}>
						<div className={classes.slideContent} style={currentSlide.slideContentStyle}>
							{currentSlide.htmlContent && (
								<div className="mb-4" dangerouslySetInnerHTML={{ __html: currentSlide.htmlContent }} />
							)}
							<div className="text-center">
								<Button {...currentSlide.action}>{currentSlide.action.title}</Button>
							</div>
						</div>
					</div>
				</CSSTransition>
			</TransitionGroup>
			<div className={classes.carouselControls}>
				<Button className={classes.arrowButton} variant="link" onClick={handlePreviousButtonClick}>
					<ChevronLeft className="text-white" />
				</Button>
				<ul>
					{slides.map((_slide, slideIndex) => (
						<li
							key={slideIndex}
							className={classNames({
								active: currentSlideIndex === slideIndex,
							})}
							onClick={() => handlePageButtonClick(slideIndex)}
						/>
					))}
				</ul>
				<Button className={classes.arrowButton} variant="link" onClick={handleNextButtonClick}>
					<ChevronRight className="text-white" />
				</Button>
			</div>
		</div>
	);
};
